import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tm1Button, Tm1TextField } from '@tm1/design-system-react';
import './styles.scss';
const Stepper = ({ value, onValueChange, placeholder, disabled, maxQuantity }) => {
    const isNumericRegex = /^\d*$/;
    const minQuantity = 0;
    const valueOrDefault = value || 0;
    const isMinusDisabled = disabled || valueOrDefault <= minQuantity;
    const isPlusDisabled = disabled || valueOrDefault >= maxQuantity;
    const textValueToNumber = (textValue) => (textValue !== '' ? parseInt(textValue, 10) : undefined);
    const numberToTextValue = (numberValue) => (numberValue !== undefined ? `${numberValue}` : '');
    const onFocus = (event) => {
        event.target.select();
    };
    const onMinusClick = () => {
        onValueChange(valueOrDefault > 1 ? valueOrDefault - 1 : undefined);
    };
    const onPlusClick = () => {
        onValueChange(valueOrDefault < maxQuantity ? valueOrDefault + 1 : maxQuantity);
    };
    const onTextInput = (event) => {
        const target = event.target;
        if (isNumericRegex.test(target.value)) {
            const newValue = textValueToNumber(target.value);
            if ((newValue || 0) > maxQuantity) {
                onValueChange(maxQuantity);
            }
            else {
                onValueChange(newValue !== 0 ? newValue : undefined);
            }
        }
        else {
            const previousValue = numberToTextValue(value);
            target.value = previousValue;
        }
    };
    const getIconClassNames = (iconDisabled) => `stepper__icon ${iconDisabled ? 'stepper__icon--disabled' : ''}`;
    return (_jsxs("div", { className: "stepper", children: [_jsx(Tm1Button, { size: "small", icon: "minus", className: getIconClassNames(isMinusDisabled), onClick: onMinusClick, disabled: isMinusDisabled, "data-testid": "stepper-minus-icon", variant: "lightweight" }), _jsx(Tm1TextField, { value: numberToTextValue(value), onInput: onTextInput, onFocus: onFocus, placeholder: placeholder, inputSize: "regular", size: 1, disabled: disabled, className: "stepper__text-field", "data-testid": "stepper-text-field", name: "stepper-text-field" }), _jsx(Tm1Button, { size: "small", icon: "plus", className: getIconClassNames(isPlusDisabled), onClick: onPlusClick, disabled: isPlusDisabled, "data-testid": "stepper-plus-icon", variant: "lightweight" })] }));
};
export default Stepper;
