import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tm1Button, Tm1TextField, Tm1Typography } from '@tm1/design-system-react';
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { v4 as uuidV4 } from 'uuid';
import messages from '../../../printing-message';
import { isValid, parseRemoteServer, stringifyRemoteServer } from './remote-server-parser';
import '../EditPrinterConfiguration.scss';
export const InputRemoteServer = ({ disabled, onChange, defaultRemoteServer, intl, connectionError, readOnly, loading, showValidationMessage, }) => {
    const [hasFormatError, setHasFormatError] = useState(false);
    const [value, setValue] = useState('');
    const [uniqueId] = useState(() => uuidV4());
    const validateRemoteAddress = (newValue) => {
        if (isValid(newValue)) {
            setHasFormatError(false);
            onChange(parseRemoteServer(newValue));
        }
        else {
            setHasFormatError(true);
            onChange(null);
        }
    };
    useEffect(() => {
        if (defaultRemoteServer) {
            setValue(stringifyRemoteServer(defaultRemoteServer));
        }
    }, [defaultRemoteServer, setValue]);
    const getErrorMessage = () => {
        if (hasFormatError) {
            return intl.formatMessage(messages.printerRemoteServerError);
        }
        if (connectionError) {
            return intl.formatMessage(connectionError);
        }
        if (showValidationMessage && !value) {
            return intl.formatMessage(messages.aValueIsRequired);
        }
        return undefined;
    };
    return (_jsxs("div", { className: "input-remote-server", children: [_jsx("label", { className: "input-remote-server__label", htmlFor: uniqueId, children: _jsx(Tm1Typography, { type: "label-helpertext-regular", children: intl.formatMessage(messages.printerRemoteServerLabel) }) }), _jsx("div", { className: "input-remote-server__fields", children: readOnly ? (_jsx(Tm1Typography, { type: "body-m", children: value })) : (_jsxs(_Fragment, { children: [_jsx(Tm1TextField, { disabled: disabled || loading, error: getErrorMessage(), id: uniqueId, onInput: (e) => setValue(e.target.value), placeholder: intl.formatMessage(messages.printerRemoteServerPlaceholder), value: value }), _jsx("div", { children: _jsx(Tm1Button, { variant: "outline", className: "input-remote-server__check-connection", onClick: () => validateRemoteAddress(value), isLoading: loading, children: intl.formatMessage(messages.printerRemoteServerCheckConnection) }) })] })) })] }));
};
export default injectIntl(InputRemoteServer);
