import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tm1Option, Tm1Select, Tm1Typography } from '@tm1/design-system-react';
import { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { v4 as uuidV4 } from 'uuid';
import { IconRefresh } from '../../../../components/icons';
import { printerIdMapper, printerNameMapper } from '../../../mapper';
import messages from '../../../printing-message';
import '../EditPrinterConfiguration.scss';
export const DropdownPrinterList = ({ hasConnectionError, printers, disabled, defaultPrinter, onRefresh, onChange, intl, readOnly, showValidationMessage, }) => {
    const [selectedPrinter, setSelectedPrinter] = useState();
    const [uniqueId] = useState(() => uuidV4());
    const findPrinterById = useCallback((printerId) => printers.find((printer) => printerIdMapper(printer) === printerId), [printers]);
    const onPrinterChange = (event) => {
        const newValue = (event.target.value || '').trim() || undefined;
        if (newValue) {
            const printerFound = findPrinterById(newValue);
            if (printerFound) {
                setSelectedPrinter(printerIdMapper(printerFound));
                onChange(printerFound);
            }
            else {
                setSelectedPrinter(undefined);
                onChange(null);
            }
        }
    };
    useEffect(() => {
        if (defaultPrinter) {
            const maybePrinter = findPrinterById(printerIdMapper(defaultPrinter));
            if (maybePrinter) {
                const printerId = printerIdMapper(maybePrinter);
                setSelectedPrinter(printerId);
            }
            else if (!readOnly) {
                onChange(null);
            }
        }
        else {
            setSelectedPrinter(undefined);
        }
    }, [defaultPrinter, findPrinterById, onChange, readOnly]);
    const getErrorMessage = () => {
        let errorMessage = '';
        if (!hasConnectionError && !disabled && !printers.length) {
            errorMessage = intl.formatMessage(messages.noPrinterFoundError);
        }
        else if (showValidationMessage && !selectedPrinter) {
            errorMessage = intl.formatMessage(messages.aSelectionIsRequired);
        }
        return errorMessage;
    };
    return (_jsxs("div", { className: "dropdown-printer-list", children: [_jsx("label", { className: "dropdown-printer-list__label", htmlFor: uniqueId, children: _jsx(Tm1Typography, { type: "label-helpertext-regular", children: intl.formatMessage(messages.printerListLabel) }) }), _jsx("div", { className: "dropdown-printer-list__fields", children: readOnly ? (_jsx(Tm1Typography, { type: "body-m", children: defaultPrinter === null || defaultPrinter === void 0 ? void 0 : defaultPrinter.name })) : (_jsxs(_Fragment, { children: [_jsx(Tm1Select, { disabled: disabled, error: getErrorMessage(), id: uniqueId, onChange: onPrinterChange, placeholder: intl.formatMessage(messages.printerListPlaceholder), value: selectedPrinter, children: printers.map((printer) => {
                                const printerId = printerIdMapper(printer);
                                const printerName = printerNameMapper(printer);
                                return (_jsx(Tm1Option, { value: printerId, children: printerName }, printerId));
                            }) }), _jsx("div", { children: readOnly ? undefined : _jsx(IconRefresh, { disabled: disabled, onClick: onRefresh }) })] })) })] }));
};
export default injectIntl(DropdownPrinterList);
