import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { selectCurrentEventSellClasses } from '../store/selectors/current-event.selector';
export const useGetSellClassDisplayName = () => {
    const eventSellClasses = useSelector(selectCurrentEventSellClasses);
    return (sellClass) => {
        const overrideSellClass = eventSellClasses === null || eventSellClasses === void 0 ? void 0 : eventSellClasses.find((it) => it.id === sellClass.id);
        return (overrideSellClass === null || overrideSellClass === void 0 ? void 0 : overrideSellClass.name) || sellClass.name;
    };
};
export const withGetSellClassDisplayName = (WrappedComponent) => (props) => {
    const getSellClassDisplayName1 = useGetSellClassDisplayName();
    return _jsx(WrappedComponent, { ...props, getSellClassDisplayName: getSellClassDisplayName1 });
};
