import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DropDownGroup, DropDownOption, SearchComponent } from '@ticketmaster/aurora';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { RootDocumentContext } from '../../context/root-document';
import sharedMessages from '../../messages';
import './styles.scss';
import { withGetSellClassDisplayName } from '../../utils/use-get-sell-class-display-name';
export class SellTypesDropdown extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = (event) => {
            if (event.target.value) {
                const options = {
                    threshold: 0.4,
                    minMatchCharLength: 1,
                    keys: ['name'],
                };
                const fuse = new Fuse(this.props.sellTypes, options);
                const results = fuse.search(event.target.value);
                this.setState({
                    searchValue: event.target.value,
                    searchResults: results,
                });
            }
            else {
                this.setState({
                    searchValue: '',
                    searchResults: null,
                });
            }
        };
        this.renderDropdownOptions = () => {
            let visibleOptionsCount = 0;
            const options = this.props.sellTypes.map(({ id, name }, i) => {
                let isOptionHidden = false;
                const disabled = this.props.shouldDisableOption(name);
                if (this.state.searchResults !== null) {
                    isOptionHidden = !this.state.searchResults.some((item) => item.name === name);
                }
                const optionClassName = classNames({
                    'sell-types__dd-option': true,
                    'sell-types__dd-option--hidden': isOptionHidden,
                    'sell-types__dd-option--disabled': disabled,
                });
                if (!isOptionHidden) {
                    visibleOptionsCount += 1;
                }
                return (_jsx(DropDownOption, { className: optionClassName, index: i, value: name, onChange: disabled ? null : this.onOptionChange, children: this.props.getSellClassDisplayName({ id, name }) }, id));
            });
            const { searchValue } = this.state;
            const noMatchingOptions = searchValue && visibleOptionsCount === 0;
            if (noMatchingOptions) {
                return options.concat(_jsx("div", { className: "sell-types__not-found", children: _jsx(FormattedMessage, { ...sharedMessages.noSellTypesFound, values: { searchValue } }) }));
            }
            return options;
        };
        this.state = {
            searchValue: '',
            searchResults: null,
        };
    }
    render() {
        const searchPlaceholder = this.props.intl.formatMessage(sharedMessages.searchForType);
        const { rootDocument } = this.context;
        return (_jsxs("div", { className: "sell-types", children: [this.props.children, _jsxs(DropDownGroup, { className: "sell-types__dd", size: this.props.size, value: [this.props.selectedValue], valueOverride: [this.props.selectedValue], keywordSearch: false, withKeyboardProvider: false, onChange: this.props.onOptionChange, containerOverride: rootDocument, children: [this.props.sellTypes.length > 10 ? (_jsxs(_Fragment, { children: [_jsx(SearchComponent, { variant: "small", value: this.state.searchValue, onChange: this.onInputChange, placeholder: searchPlaceholder, onKeyDown: (event) => event.stopPropagation() }), _jsx("div", { className: "sell-types__divider" })] })) : null, this.renderDropdownOptions()] })] }));
    }
}
SellTypesDropdown.contextType = RootDocumentContext;
SellTypesDropdown.propTypes = {
    sellTypes: PropTypes.array,
    selectedValue: PropTypes.string,
    intl: intlShape.isRequired,
    onOptionChange: PropTypes.func.isRequired,
    shouldDisableOption: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    children: PropTypes.element,
    getSellClassDisplayName: PropTypes.func,
};
export default withGetSellClassDisplayName(injectIntl(SellTypesDropdown));
