import { sortInventory } from '@ticketmaster/tm1pos-web-shared/utils';
import messages from './messages';
import type { InventoryType } from './selectors/main';
import type { InjectedIntl } from 'react-intl';

export type InventoryTypeDisplay = {
  id: string;
  name: string;
  sellType: InventoryType;
};

export const formatInventoryTypes =
  (intl: InjectedIntl) =>
  (inventoryTypeList: InventoryType[] | null): InventoryTypeDisplay[] => {
    if (!inventoryTypeList) {
      return [];
    }
    const formattedInventoryTypes = inventoryTypeList.map((it) => formatInventoryType(it, intl));

    return sortInventory(formattedInventoryTypes);
  };

export const formatInventoryType = (inventoryType: InventoryType, intl: InjectedIntl): InventoryTypeDisplay => {
  let name;
  if (inventoryType.parent) {
    if (inventoryType.isAccessible) {
      name = intl.formatMessage(messages.inventoryTypeQualifiedHoldLabelAccessible, {
        name: `${inventoryType.parent} - ${inventoryType.name}`,
      });
    } else {
      name = `${inventoryType.parent} - ${inventoryType.name}`;
    }
  } else {
    name = inventoryType.name;
  }

  return {
    id: inventoryType.id,
    name, // note: this field is used for sorting, the 'sellType' field will be used for display purpose
    sellType: inventoryType,
  };
};

export const getSellTypeFromDisplayName = (
  inventoryType: InventoryTypeDisplay[],
  displayName: string | null,
): InventoryType | null => {
  const maybeInventoryType = inventoryType.find((it) => it.name === displayName);

  return maybeInventoryType ? maybeInventoryType.sellType : null;
};

export const getSelectedDisplayName = (
  inventoryType: InventoryTypeDisplay[],
  selectedInventoryType: InventoryType | null,
): string | null => {
  if (!selectedInventoryType) {
    return null;
  }

  const maybeSelectedInventoryTypeDisplay = inventoryType.find((it) => it.id === selectedInventoryType?.id);

  return maybeSelectedInventoryTypeDisplay ? maybeSelectedInventoryTypeDisplay.name : null;
};
