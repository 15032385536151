import { gql } from '@apollo/client';
import { asString } from '../../utils/graphQL/graphql-utils';
export const SEARCH_EVENTS_QUERY = asString(gql `
  query eventsV2($startDateTime: UTCDateTime, $endDateTime: UTCDateTime, $showPastEvents: Boolean) {
    eventsV2(startDateTime: $startDateTime, endDateTime: $endDateTime, showPastEvents: $showPastEvents) {
      id
      inventoryIds {
        host
        arx
      }
      name
      hostName
      eventCode
      date
      endDate
      description
      onSale
      offSale
      rotatingEntryTokenEnabled
      terminalId
      location {
        city
        state
        venue {
          name
          mapId
        }
        localTimeUTCOffsetMinutes
      }
    }
  }
`);
export const CLIENT_CLOUD_LOGS_MUTATION = asString(gql `
  mutation clientLogs($type: String, $status: String, $data: String) {
    clientLogs(type: $type, data: $data, status: $status)
  }
`);
export const USER_INFORMATION_QUERY = asString(gql `
  query userInformation {
    userInformation {
      tm1posAuthToken
      splunkRumToken
      profile {
        firstName
        lastName
        username
        profileId
        organizationId
        organizationName
        language
        salesforceOrgId
        countryCode
        roles
        userTrackingInformation {
          organizationType
          organizationSubType
          providerId
          segment
          cdd
          csm
          css
          salesforceUserId
          email
        }
      }
      host {
        hostName
        opcode
      }
      archtics {
        dsn
        userId
      }
      permissions {
        host {
          hostName
          permissions {
            canRefund
            canRefundPrinted
            canUseTmdsPayment
            canUsePdg
            canUseCcManualEntryWithoutEmv
            canLinkGlobalAccount
            mustAuthorizeCards
            sellClasses {
              id
              name
            }
            defaultSellClass {
              id
              name
            }
            paymentMethods {
              type
              category
              description
            }
            languageName
            feeEquivalenceClassId
          }
        }
        archtics {
          canRefund
          canRefundPrinted
          canUseTmdsPayment
          canUsePdg
          canUseCcManualEntryWithoutEmv
          mustAuthorizeCards
          sellClasses {
            id
            name
          }
          paymentMethods {
            type
            category
            description
          }
        }
      }
      enabledFeatureFlags
    }
  }
`);
export const paymentStatusQuery = (paymentReference) => asString(gql `
    query paymentStatus {
        paymentStatus(paymentReference: "${paymentReference}") {
            transaction {
                status,
                error
            },
            card {
                type
                lastFourDigits
            }
        }
    }
`);
export const startPaymentMutation = (deviceId, eventId, eventCode, hostId, opCode, manualEntry, amount, currency, qrCodeProvider) => asString(gql `
    mutation startPayment {
      startPayment(
        deviceId: "${deviceId}",
        eventId: "${eventId}",
        eventCode: "${eventCode}",
        hostId: "${hostId}",
        opCode: "${opCode}",
        manualEntry: ${manualEntry},
        amount: ${amount},
        currency: "${currency}"
        qrCodeProvider: "${qrCodeProvider}"
      )
    }
 `);
export const PAYMENT_DEVICES_QUERY = asString(gql `
  query paymentDevices {
    paymentDevices {
      id
      name
    }
  }
`);
export const cancelPaymentMutation = (paymentReference) => asString(gql `
    mutation cancelPayment {
        cancelPayment(
            paymentReference: "${paymentReference}"
        )
    }
`);
export const PRINTABLE_TEST_TICKET_QUERY = asString(gql `
  query PrintableTestTicket($printableTestTicketRequest: PrintableTestTicketRequest!) {
    printableTestTicket(printableTestTicketRequest: $printableTestTicketRequest)
  }
`);
