import { gql } from '@apollo/client';
import { asString } from '@ticketmaster/tm1pos-web-shared/utils/graphQL/graphql-utils';

const CART_INFO_FRAGMENT = asString(gql`
  fragment cartInfo on Cart {
    id
    eventFees {
      id
      quantity
    }
    items {
      id
      eventId
      eventCode
      places {
        ticketType
        placeId
        section
        row
        name
        generalAdmission
        adaType
        pricing {
          total {
            amount
            currency
          }
          faceValue {
            amount
            currency
          }
          fees {
            amount
            currency
          }
          taxes {
            amount
            currency
          }
          feesAndTaxes {
            amount
            currency
          }
          feesAndTaxesBreakdown {
            id
            name
            amount {
              amount
              currency
            }
          }
        }
      }
    }
  }
`);
const PRICING_ITEMS_FRAGMENT = asString(gql`
  fragment pricingItems on Pricing {
    total {
      amount
      currency
    }
    faceValue {
      amount
      currency
    }
    fees {
      amount
      currency
    }
    taxes {
      amount
      currency
    }
    feesAndTaxes {
      amount
      currency
    }
    feesAndTaxesBreakdown {
      id
      name
      amount {
        amount
        currency
      }
    }
  }
`);
const EVENT_FEES_PRICING_ITEMS_FRAGMENT = asString(gql`
  fragment eventFeesPricingItems on Pricing {
    eventFees {
      amount
      currency
    }
    eventFeesBreakdown {
      amount {
        amount
        currency
      }
      quantity
      name
      id
    }
  }
`);

export const EMAIL_REPORT_MUTATION = asString(gql`
  mutation emailReport(
    $email: String!
    $paymentGroupsTypeLabels: [PaymentGroupsTypeLabel]
    $report: ReportInput!
    $locale: String
  ) {
    emailReport(email: $email, paymentGroupsTypeLabels: $paymentGroupsTypeLabels, report: $report, locale: $locale) {
      isSent
    }
  }
`);

export const GENERATE_REPORT_QUERY = asString(gql`
  query generateReport($startDateTime: LocalDateTime!, $endDateTime: LocalDateTime!, $zoneOffset: String!) {
    generateReport(startDateTime: $startDateTime, endDateTime: $endDateTime, zoneOffset: $zoneOffset) {
      startDateTime
      endDateTime
      generationDateTime
      operator {
        id
        fullName {
          first
          last
        }
      }
      paymentGroups {
        type
        operations {
          type
          sale {
            amount
            currency
          }
          refund {
            amount
            currency
          }
          total {
            amount
            currency
          }
        }
        groupTotal {
          type
          sale {
            amount
            currency
          }
          refund {
            amount
            currency
          }
          total {
            amount
            currency
          }
        }
      }
      grandTotal {
        type
        sale {
          amount
          currency
        }
        refund {
          amount
          currency
        }
        total {
          amount
          currency
        }
      }
    }
  }
`);

export const ARCHTICS_SEAT_AVAILABILITY_QUERY = asString(gql`
  query aapiAvailability($eventId: String!, $sellClasses: [SellClass]) {
    aapiAvailability(eventId: $eventId, sellClasses: $sellClasses) {
      status
      placeIds
      section
      sectionId
      row
      rowId
      name
      rating {
        id
        parentId
        sellable
        description
      }
      placeType {
        id
        name
        description
      }
    }
  }
`);

export const ARCHTICS_ADA_SEAT_AVAILABILITY_QUERY = asString(gql`
  query aapiAvailability($eventId: String!, $adaTypes: [AAPIPlaceTypeInput], $sellClasses: [SellClass]) {
    aapiAvailability(eventId: $eventId, adaTypes: $adaTypes, sellClasses: $sellClasses) {
      status
      placeIds
      section
      sectionId
      row
      rowId
      name
      rating {
        id
        parentId
        sellable
        description
      }
      placeType {
        id
        name
        description
      }
    }
  }
`);

export const EVENT_DETAILS_QUERY = asString(gql`
  query getEventDetails($eventId: EventId!, $feeEquivalenceClassId: String, $hostName: String) {
    event(eventId: $eventId, hostName: $hostName) {
      name
      rotatingEntryTokenEnabled
      location {
        city
        state
        countryCode
        venue {
          name
        }
      }
      date
    }
    ticketTypes(eventId: $eventId, feeEquivalenceClassId: $feeEquivalenceClassId, hostName: $hostName) {
      id
      name
      promoCodes
      expandedMaskLevel2
      inventoryOfferSetId
      inventoryExclusive
      minPrice {
        amount
        currency
      }
      maxPrice {
        amount
        currency
      }
      rates {
        id
        priceLevels
        description
        price {
          amount
          currency
        }
      }
    }
    adaTypes(eventId: $eventId, hostName: $hostName) {
      id
      name
      description
    }
    priceLevelGroups(eventId: $eventId, hostName: $hostName) {
      id
      priceLevelIds
    }
    eventFees(eventId: $eventId, hostName: $hostName) {
      id
      description
      price {
        amount
        currency
      }
      maxQuantity
    }
    sellClasses(eventId: $eventId, hostName: $hostName) {
      id
      name
    }
  }
`);

export const GENERATE_ISM_TOKEN_QUERY = asString(gql`
  query generateToken($eventId: String!) {
    token(eventId: $eventId)
  }
`);

export const PAYMENT_CERTIFICATE_QUERY = asString(gql`
  query certificate {
    certificate {
      id
      body
    }
  }
`);

export const BEST_AVAILABLE_MUTATION = asString(gql`
  mutation bestAvailable(
    $eventId: ID!
    $quantity: Int!
    $ticketType: ID!
    $useCart: ID
    $priceLevelGroups: [String]
    $hostName: String
    $eventCode: String
    $sellClass: SellClass
  ) {
    reserveBestAvailable(
      eventId: $eventId
      quantity: $quantity
      ticketType: $ticketType
      useCart: $useCart
      priceLevelGroups: $priceLevelGroups
      hostName: $hostName
      eventCode: $eventCode
      sellClass: $sellClass
    ) {
      ...cartInfo
      totals {
        ...pricingItems
        ...eventFeesPricingItems
      }
    }
  }

  ${CART_INFO_FRAGMENT}
  ${PRICING_ITEMS_FRAGMENT}
  ${EVENT_FEES_PRICING_ITEMS_FRAGMENT}
`);

export const ISM_TICKET_RESERVE_MUTATION = asString(gql`
  mutation reserveSinglePlace(
    $eventId: ID!
    $place: PlaceInput!
    $ticketType: ID!
    $useCart: ID
    $adaRequired: String
    $hostName: String
    $eventCode: String
    $sellClass: SellClass
  ) {
    reserveSinglePlace(
      eventId: $eventId
      place: $place
      ticketType: $ticketType
      useCart: $useCart
      hostName: $hostName
      eventCode: $eventCode
      adaRequired: $adaRequired
      sellClass: $sellClass
    ) {
      ...cartInfo
      totals {
        ...pricingItems
        ...eventFeesPricingItems
      }
    }
  }

  ${CART_INFO_FRAGMENT}
  ${PRICING_ITEMS_FRAGMENT}
  ${EVENT_FEES_PRICING_ITEMS_FRAGMENT}
`);

export const SET_CART_EVENT_FEES_MUTATION = asString(gql`
  mutation setCartEventFees($input: CartEventFeesInput!) {
    setCartEventFees(input: $input) {
      ...cartInfo
      totals {
        ...pricingItems
        ...eventFeesPricingItems
      }
    }
  }

  ${CART_INFO_FRAGMENT}
  ${PRICING_ITEMS_FRAGMENT}
  ${EVENT_FEES_PRICING_ITEMS_FRAGMENT}
`);

export const CHECKOUT_MUTATION = asString(gql`
  mutation checkout(
    $cartId: ID!
    $order: OrderDetails!
    $customerInfo: CustomerInfo
    $payments: [CheckoutPaymentInput]!
    $orderIdToRetry: ID
    $hostName: String
    $eventCode: String
    $deliveryMethod: DeliveryMethod
    $linkGlobalAccount: Boolean
  ) {
    checkout(
      cartId: $cartId
      order: $order
      customerInfo: $customerInfo
      payments: $payments
      orderIdToRetry: $orderIdToRetry
      hostName: $hostName
      eventCode: $eventCode
      deliveryMethod: $deliveryMethod
      linkGlobalAccount: $linkGlobalAccount
    ) {
      order {
        id
        accountId
        payments {
          id
          status
        }
        totals {
          ...pricingItems
          ...eventFeesPricingItems
        }
      }
    }
  }

  ${PRICING_ITEMS_FRAGMENT}
  ${EVENT_FEES_PRICING_ITEMS_FRAGMENT}
`);

export const DELIVERY_MUTATION = asString(gql`
  mutation deliver(
    $orderId: ID!
    $accountId: ID
    $deliveryConfig: DeliveryConfig!
    $hostName: String
    $eventDetails: EventDetails!
    $payments: [CheckoutPaymentInput]
    $items: [OrderItem]!
    $totals: PricingInput!
  ) {
    deliveryResult: deliver(
      orderId: $orderId
      accountId: $accountId
      deliveryConfig: $deliveryConfig
      hostName: $hostName
      eventDetails: $eventDetails
      payments: $payments
      items: $items
      totals: $totals
    ) {
      documents {
        body {
          base64
        }
        rfidTags
        contentType
        templateNames
        numPages
      }
    }
  }
`);

export const deliverMutation = asString(gql`
  mutation deliver($request: DeliveryRequest!) {
    deliver(request: $request)
  }
`);

export const UNRESERVE_CART_ITEM_MUTATION = asString(gql`
  mutation unreserve(
    $eventId: ID
    $cart: CartInput!
    $cartItemId: ID!
    $seatName: String
    $hostName: String
    $generalAdmissionPlace: Boolean
  ) {
    unreserve(
      eventId: $eventId
      cart: $cart
      cartItemId: $cartItemId
      seatName: $seatName
      hostName: $hostName
      generalAdmissionPlace: $generalAdmissionPlace
    ) {
      ...cartInfo
      totals {
        ...pricingItems
        ...eventFeesPricingItems
      }
    }
  }

  ${CART_INFO_FRAGMENT}
  ${PRICING_ITEMS_FRAGMENT}
  ${EVENT_FEES_PRICING_ITEMS_FRAGMENT}
`);

export const CLEAR_CART_MUTATION = asString(gql`
  mutation deleteCart($cartId: ID!) {
    deleteCart(cartId: $cartId) {
      statusCode
    }
  }
`);

export const CANCEL_ORDER_MUTATION = asString(gql`
  mutation cancelOrder(
    $orderId: ID!
    $cartId: ID
    $hostName: String
    $sellClass: SellClass
    $defaultSellClass: SellClass
  ) {
    cancelOrder(
      orderId: $orderId
      cartId: $cartId
      hostName: $hostName
      sellClass: $sellClass
      defaultSellClass: $defaultSellClass
    ) {
      statusCode
    }
  }
`);

export const USER_TRACKING_MUTATION = asString(gql`
  mutation userTracking {
    userTracking
  }
`);
